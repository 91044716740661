<template>
  <b-container class="bv-example-row">
    <b-row v-show="loading">
      <b-col
        cols="12"
        :md="numberInRow"
        sm="12"
        v-for="item in itemNumber"
        :key="item"
      >
        <b-skeleton-img :height="height" />
        <b-card>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </b-card>

        <br />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow,
  BSkeleton,
  BCol,
  BSkeletonImg,
  BContainer,
  BCard,
} from "bootstrap-vue";

export default {
  name: "SkeletonList",
  components: {
    BRow,
    BCol,
    BContainer,
    BSkeletonImg,
    BSkeleton,
    BCard,
  },
  props: {
    loading: {
      type: Boolean,
    },
    numberInRow: {
      type: Number,
    },
    itemNumber: {
      type: Number,
    },
    height: {
      type: String,
    },
  },
};
</script>

<style lang="CSS" scoped></style>
