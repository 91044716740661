var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('b-container',[_c('content-with-sidebar',{staticClass:"blog-wrapper blogs-page"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[(!_vm.loading)?_c('div',[_c('skeleton-list-blogs',{attrs:{"loading":!_vm.loading,"item-number":6,"number-in-row":4,"height":'1500'}})],1):_vm._e()])],1),(_vm.loading)?_c('b-row',{staticClass:"blog-list-wrapper"},[_vm._l((_vm.blogList2),function(blog){return _c('b-col',{key:blog._id,attrs:{"md":"4"}},[_c('b-card',{attrs:{"tag":"article","no-body":""}},[_c('b-link',{attrs:{"to":{ name: 'pages-blog-detail', params: { id: blog._id } }}},[_c('b-img',{staticClass:"card-img-top",attrs:{"src":blog.files ? blog.files.fileUrl : null,"alt":blog.files ? blog.files.name : null,"height":"230"}})],1),_c('b-card-body',[_c('b-card-title',[_c('b-link',{staticClass:"blog-title-truncate text-body-heading",attrs:{"to":{
                    name: 'pages-blog-detail',
                    params: { id: blog._id },
                  }}},[_vm._v(" "+_vm._s(blog.title)+" ")])],1),_c('b-media',{attrs:{"no-body":""}},[_c('b-media-body',[_c('small',{staticClass:"text-muted mr-50"},[_vm._v("posted in:")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.moment(blog.createdAt).format("LL [at] h:mm A z")))])])],1),_c('b-card-text',{staticClass:"blog-content-truncate"},[_vm._v(" "+_vm._s(_vm.strippedContent(blog.content))+" ")]),_c('hr')],1)],1)],1)}),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"my-2"},[_c('b-pagination',{attrs:{"per-page":_vm.perPage,"align":"center","total-rows":_vm.rows,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],2):_vm._e(),_c('div',{staticClass:"blog-sidebar py-2 py-lg-0",attrs:{"slot":"sidebar"},slot:"sidebar"},[_c('div',{staticClass:"blog-recent-posts"},[_c('h6',{staticClass:"section-label mb-75"},[_vm._v(_vm._s(_vm.$t("common.RECENT_POSTS")))]),(!_vm.loading)?_c('div',[_c('skeleton-sidebar-blogs',{attrs:{"loading":!_vm.loading,"height":'147px'}})],1):_vm._e(),(_vm.loading)?_c('div',_vm._l((_vm.blogSide),function(recentpost,index){return _c('b-media',{key:recentpost.img,class:index ? 'mt-2' : '',attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-link',{attrs:{"to":{
                    name: 'pages-blog-detail',
                    params: { id: recentpost._id },
                  }}},[_c('b-img',{attrs:{"src":recentpost.files ? recentpost.files.fileUrl : null,"alt":recentpost.files ? recentpost.files.name : null,"width":"100","rounded":"","height":"80"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"blog-recent-post-title"},[_c('b-link',{staticClass:"text-body-heading",attrs:{"to":{
                      name: 'pages-blog-detail',
                      params: { id: recentpost._id },
                    }}},[_vm._v(" "+_vm._s(recentpost.title)+" ")])],1),_c('span',{staticClass:"text-muted mb-0"},[_vm._v(" "+_vm._s(_vm.moment(recentpost.createdAt).format("LL [at] h:mm A z"))+" ")])])],1)}),1):_vm._e()]),_c('div',{staticClass:"blog-categories mt-3"},[_c('h6',{staticClass:"section-label mb-1"},[_vm._v("Categories")]),_vm._l((_vm.categories),function(category){return _c('div',{key:category.icon,staticClass:"d-flex justify-content-start align-items-center mb-75"},[_c('b-link',[_c('b-avatar',{staticClass:"mr-75",attrs:{"rounded":"","size":"32","variant":_vm.tagsColor(category.category)}},[_c('feather-icon',{attrs:{"icon":category.icon,"size":"16"}})],1)],1),_c('b-link',[_c('div',{staticClass:"blog-category-title text-body"},[_vm._v(" "+_vm._s(category.category)+" ")])])],1)})],2)])],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }