<template>
  <div>
    <Header />
    <b-container>
      <content-with-sidebar class="blog-wrapper blogs-page">
        <b-row>
          <b-col md="12">
            <div v-if="!loading">
              <skeleton-list-blogs
                :loading="!loading"
                :item-number="6"
                :number-in-row="4"
                :height="'1500'"
              />
            </div>
          </b-col>
        </b-row>

        <!-- blogs -->
        <b-row class="blog-list-wrapper" v-if="loading">
          <b-col v-for="blog in blogList2" :key="blog._id" md="4">
            <b-card tag="article" no-body>
              <b-link
                :to="{ name: 'pages-blog-detail', params: { id: blog._id } }"
              >
                <b-img
                  :src="blog.files ? blog.files.fileUrl : null"
                  :alt="blog.files ? blog.files.name : null"
                  class="card-img-top"
                  height="230"
                />
              </b-link>
              <b-card-body>
                <b-card-title>
                  <b-link
                    :to="{
                      name: 'pages-blog-detail',
                      params: { id: blog._id },
                    }"
                    class="blog-title-truncate text-body-heading"
                  >
                    {{ blog.title }}
                  </b-link>
                </b-card-title>
                <b-media no-body>
                  <!-- <b-media-aside vertical-align="center" class="mr-50">
                    <b-avatar
                      href="javascript:void(0)"
                      size="24"
                      :src="blog.avatar"
                    />
                  </b-media-aside> -->
                  <b-media-body>
                    <small class="text-muted mr-50">posted in:</small>
                    <!-- <small>
                      <b-link class="text-body">{{ blog.userFullName }}</b-link>
                    </small>
                    <span class="text-muted ml-75 mr-50">|</span> -->
                    <small class="text-muted">{{
                      moment(blog.createdAt).format("LL [at] h:mm A z")
                    }}</small>
                  </b-media-body>
                </b-media>
                <!-- <div class="my-1 py-25">
                  <b-link v-for="(tag, index) in blog.tags" :key="index">
                    <b-badge pill class="mr-75" :variant="tagsColor(tag)">
                      {{ tag }}
                    </b-badge>
                  </b-link>
                </div> -->
                <b-card-text class="blog-content-truncate">
                  {{ strippedContent(blog.content) }}
                </b-card-text>
                <hr />
                <!-- <div class="d-flex justify-content-between align-items-center">
                  <b-link :to="{ path: `/pages/blog/${blog.id}#blogComment` }">
                    <div class="d-flex align-items-center text-body">
                      <feather-icon icon="MessageSquareIcon" class="mr-50" />
                      <span class="font-weight-bold"
                        >{{ kFormatter(blog.comment) }} Comments</span
                      >
                    </div>
                  </b-link>
                  <b-link
                    :to="{ name: 'pages-blog-detail', params: { id: blog.id } }"
                    class="font-weight-bold"
                  >
                    Read More
                  </b-link>
                </div> -->
              </b-card-body>
            </b-card>
          </b-col>

          <b-col cols="12">
            <!-- pagination -->
            <div class="my-2">
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                align="center"
                :total-rows="rows"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>

        <!--/ blogs -->

        <!-- sidebar -->

        <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
          <!-- input search -->
          <!-- <b-form-group class="blog-search">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="search-input"
                v-model="search_query"
                placeholder="Search here"
              />
              <b-input-group-append class="cursor-pointer" is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group> -->
          <!--/ input search -->

          <!-- recent posts -->
          <div class="blog-recent-posts">
            <h6 class="section-label mb-75">{{ $t("common.RECENT_POSTS") }}</h6>

            <div v-if="!loading">
              <skeleton-sidebar-blogs :loading="!loading" :height="'147px'" />
            </div>
            <div v-if="loading">
              <b-media
                v-for="(recentpost, index) in blogSide"
                :key="recentpost.img"
                no-body
                :class="index ? 'mt-2' : ''"
              >
                <b-media-aside class="mr-2">
                  <b-link
                    :to="{
                      name: 'pages-blog-detail',
                      params: { id: recentpost._id },
                    }"
                  >
                    <b-img
                      :src="recentpost.files ? recentpost.files.fileUrl : null"
                      :alt="recentpost.files ? recentpost.files.name : null"
                      width="100"
                      rounded
                      height="80"
                    />
                  </b-link>
                </b-media-aside>
                <b-media-body>
                  <h6 class="blog-recent-post-title">
                    <b-link
                      :to="{
                        name: 'pages-blog-detail',
                        params: { id: recentpost._id },
                      }"
                      class="text-body-heading"
                    >
                      {{ recentpost.title }}
                    </b-link>
                  </h6>
                  <span class="text-muted mb-0">
                    {{
                      moment(recentpost.createdAt).format("LL [at] h:mm A z")
                    }}
                  </span>
                </b-media-body>
              </b-media>
            </div>
          </div>
          <!--/ recent posts -->

          <!-- categories -->
          <div class="blog-categories mt-3">
            <h6 class="section-label mb-1">Categories</h6>

            <div
              v-for="category in categories"
              :key="category.icon"
              class="d-flex justify-content-start align-items-center mb-75"
            >
              <b-link>
                <b-avatar
                  rounded
                  size="32"
                  :variant="tagsColor(category.category)"
                  class="mr-75"
                >
                  <feather-icon :icon="category.icon" size="16" />
                </b-avatar>
              </b-link>
              <b-link>
                <div class="blog-category-title text-body">
                  {{ category.category }}
                </div>
              </b-link>
            </div>
          </div>
          <!--/ categories -->
        </div>
        <!--/ sidebar -->
      </content-with-sidebar>
    </b-container>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import SkeletonListBlogs from "@/views/nftdubai/components/SkeltonListBlogs.vue";
import SkeletonSidebarBlogs from "@/views/nftdubai/components/SkeltonSidebarBlogs.vue";
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";

export default {
  components: {
    Header,
    Footer,
    BContainer,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
    SkeletonListBlogs,
    SkeletonSidebarBlogs,
  },
  data() {
    return {
      loading: false,
      search_query: "",
      blogList2: [],
      blogList2Count: "",
      blogSide: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 9,
      rows: "",

      // categories
      categories: [
        { category: "NFT", icon: "WatchIcon" },
        { category: "Blockchain", icon: "ShoppingCartIcon" },
        { category: "Recruitment", icon: "CommandIcon" },
        { category: "Project", icon: "HashIcon" },
        // { category: "Video", icon: "VideoIcon" },
      ],
    };
  },
  created() {
    this.getBlogs();
    this.getBlogsSide();
  },
  watch: {
    currentPage: {
      immediate: true,
      handler() {
        this.getBlogs();
        // console.log(this.currentPage + " >>" + this.perPage);
      },
    },
    // perPage: {
    //   immediate: true,
    //   handler() {
    //     this.getBlogs();
    //     // console.log(this.currentPage + " >>" + this.perPage);
    //   },
    // },
  },

  methods: {
    kFormatter,
    moment: function (input) {
      return moment(input);
    },
    strippedContent: function (string) {
      string = string.replace(new RegExp("<[^>]*>", "g"), "");
      return string.replace(new RegExp("&nbsp;", "g"), " ");
    },
    tagsColor(tag) {
      if (tag === "Quote") return "light-info";
      if (tag === "Gaming") return "light-danger";
      if (tag === "Fashion") return "light-primary";
      if (tag === "Video") return "light-warning";
      if (tag === "Food") return "light-success";
      return "light-primary";
    },

    getBlogs() {
      const lang = localStorage.getItem("language") || "en";

      const formData = new FormData();
      // formData.append("q", searchQuery.value);
      formData.append("page", this.currentPage);
      formData.append("perPage", this.perPage);
      formData.append("language", lang);
      formData.append("type", "blog");

      axios
        .post("posts/search/", formData)
        .then((response) => {
          this.blogList2 = response.data.data;
          this.rows = response.data.count;
        })
        .then(() => {
          this.loading = true;
        });
    },
    getBlogsSide() {
      const lang = localStorage.getItem("language") || "en";

      const formData = new FormData();
      // formData.append("q", searchQuery.value);
      // formData.append("page", currentPage.value);
      formData.append("perPage", 2);
      formData.append("language", lang);
      formData.append("type", "blog");

      axios
        .post("posts/search/", formData)
        .then((response) => {
          this.blogSide = response.data.data;
          // this.rows = response.data.data.length;
        })
        .then(() => {
          this.loading = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.blogs-page {
  margin-top: 110px;
  margin-bottom: 110px;
}

.blogs-page .media-body {
  display: contents;
}
.blogs-page .media {
  margin-bottom: 10px;
}
.blogs-page .card-img-top {
  max-height: 245px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.page-item.active .page-link {
  background-color: #366ce3;
}
.blogs-page .media-body {
  display: block;
}
.blog-list-wrapper .blog-title-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
